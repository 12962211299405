import { FC, ReactNode, CSSProperties, useRef, useEffect } from "react";
import { DropTargetMonitor, useDrop, XYCoord } from "react-dnd";
import { CellCoordinates } from "../../types";
import styles from "./Grid.module.scss";

type Props = {
  cell: CellCoordinates;
  cellOffset?: CellCoordinates;
  canDrop?: (itemID: string, cell: CellCoordinates) => boolean;
  onDrop?: (itemID: string, cell: CellCoordinates) => void;
  onHover?: (itemID: string, cell: CellCoordinates) => void;
  dragTypesAccepted: string[];
  useDropDependencies: Array<any>;
  cellSize: number;
  children?: ReactNode;
  style?: CSSProperties;
};

const DraggableGridCell: FC<Props> = ({
  cell,
  canDrop,
  onDrop,
  onHover,
  dragTypesAccepted,
  useDropDependencies,
  cellSize,
  children,
  style,
}) => {
  const childIDRef = useRef<string | null>(null);

  useEffect(() => {
    // @ts-ignore
    childIDRef.current = children?.props?.id || null;
  }, [children]);

  const [, drop] = useDrop(
    () => ({
      accept: dragTypesAccepted,
      canDrop: canDrop
        ? (_, monitor) => {
            const itemType = monitor.getItemType() as string;
            // if all objects fit within one cell, you don't need this offset calculation, replace targetCell with cell
            const offset =
              childIDRef.current === itemType ? null : getOffset(monitor);
            const targetCell: CellCoordinates = offset
              ? {
                  row: cell.row - Math.trunc(offset.y / cellSize),
                  col: cell.col - Math.trunc(offset.x / cellSize),
                }
              : cell;
            return canDrop(itemType, targetCell);
          }
        : undefined,
      drop: onDrop
        ? (_, monitor) => {
            const itemType = monitor.getItemType() as string;
            // if all objects fit within one cell, you don't need this offset calculation, replace targetCell with cell
            const offset =
              childIDRef.current === itemType ? null : getOffset(monitor);
            const targetCell: CellCoordinates = offset
              ? {
                  row: cell.row - Math.trunc(offset.y / cellSize),
                  col: cell.col - Math.trunc(offset.x / cellSize),
                }
              : cell;
            return onDrop(itemType, targetCell);
          }
        : undefined,
      hover: onHover
        ? (_, monitor) => {
            const itemType = monitor.getItemType() as string;
            // if all objects fit within one cell, you don't need this offset calculation, replace targetCell with cell
            const offset =
              childIDRef.current === itemType ? null : getOffset(monitor);
            const targetCell: CellCoordinates = !!offset
              ? {
                  row: cell.row - Math.trunc(offset.y / cellSize),
                  col: cell.col - Math.trunc(offset.x / cellSize),
                }
              : cell;
            return onHover(itemType, targetCell);
          }
        : undefined,
      collect: (monitor) => ({ isOver: !!monitor.isOver() }),
    }),
    useDropDependencies
  );

  return (
    <div
      ref={drop}
      className={styles.GridCell}
      style={{ height: cellSize, width: cellSize, ...style }}
    >
      {children}
    </div>
  );
};

const getOffset = (
  monitor: DropTargetMonitor<unknown, unknown>
): XYCoord | null => {
  const clientOffset = monitor.getInitialClientOffset();
  const sourceOffset = monitor.getInitialSourceClientOffset();
  const offset =
    !!clientOffset && !!sourceOffset
      ? {
          x: clientOffset.x - sourceOffset.x,
          y: clientOffset.y - sourceOffset.y,
        }
      : null;
  return offset;
};

export default DraggableGridCell;
