import { FC } from "react";
import DraggableGridCell from "./DraggableGridCell";
import { CellCoordinates, ChildrenByCell, StylesByCell } from "../../types";

type Props = {
  rows: number;
  columns: number;
  cellSize: number;
  childrenByCell: ChildrenByCell;
  stylesByCell?: StylesByCell;
  dragTypesAccepted: string[];
  useDropDependencies: Array<any>;
  canDrop?: (itemID: string, cell: CellCoordinates) => boolean;
  onDrop?: (itemID: string, cell: CellCoordinates) => void;
  onHover?: (itemID: string, cell: CellCoordinates) => void;
};

const DraggableGrid: FC<Props> = ({
  rows,
  columns,
  cellSize,
  dragTypesAccepted,
  useDropDependencies,
  childrenByCell,
  stylesByCell,
  canDrop,
  onDrop,
  onHover,
}) => {
  // this needs to be a descendant of a DndProvider (see PlaceShipsScreen.tsx)
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {[...Array(rows)].map((_, row) => (
        <div key={row} style={{ display: "flex", flexDirection: "row" }}>
          {[...Array(columns)].map((_, col) => (
            <DraggableGridCell
              key={col}
              cell={{ row, col }}
              dragTypesAccepted={dragTypesAccepted}
              useDropDependencies={useDropDependencies}
              cellSize={cellSize}
              canDrop={canDrop}
              onDrop={onDrop}
              onHover={onHover}
              style={stylesByCell && stylesByCell[`${row},${col}`]}
            >
              {childrenByCell[`${row},${col}`] || null}
            </DraggableGridCell>
          ))}
        </div>
      ))}
    </div>
  );
};

export default DraggableGrid;
