import { FC } from "react";
import { GiBrightExplosion } from "react-icons/gi";
import { VscCircle } from "react-icons/vsc";
import styles from "./GameGridIcons.module.scss";

type Props = {
  width: number;
  height: number;
  color?: string;
};

export const Explosion: FC<Props> = ({ width, height, color }) => (
  <GiBrightExplosion
    className={styles.absolutePosition}
    style={{ width, height, color }}
  />
);

export const NoShip: FC<Props> = ({ width, height, color }) => (
  <VscCircle
    className={styles.absolutePosition}
    style={{ width, height, color }}
  />
);
