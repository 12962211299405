import { FC, ReactNode } from "react";
import { useDrop } from "react-dnd";
import { ShipID, shipIDs } from "../content/Ships";

type Props = {
  children: ReactNode;
  onDrop: (shipID: ShipID) => void;
  onHover: (shipID: ShipID) => void;
  useDropDependencies: Array<any>;
  className?: string;
};

const BackgroundShipDrop: FC<Props> = ({
  children,
  onDrop,
  onHover,
  useDropDependencies,
  className,
}) => {
  const [, drop] = useDrop(
    () => ({
      accept: [...shipIDs],
      drop: (_, monitor) => {
        if (monitor.didDrop()) return;
        onDrop(monitor.getItemType() as ShipID);
      },
      hover: (_, monitor) => onHover(monitor.getItemType() as ShipID),
      collect: (monitor) => ({ isOver: !!monitor.isOver() }),
    }),
    useDropDependencies
  );

  return (
    <div ref={drop} className={className}>
      {children}
    </div>
  );
};

export default BackgroundShipDrop;
