import { FC } from "react";
import { useDrag } from "react-dnd";
import { ShipID, SHIP_INVENTORY } from "../../content/Ships";

type Props = {
  id: ShipID;
  cellSize: number;
  orientation: "ACROSS" | "DOWN";
  onClick?: () => void;
};

export const Ship: FC<Props> = ({ id, cellSize, orientation, onClick }) => {
  const ship = SHIP_INVENTORY[id];
  const [longSide, shortSide] = ship.dimensions;

  const isDown = orientation === "DOWN";

  return (
    <img
      id={id}
      alt={id}
      src={isDown ? ship.imageSourceDown : ship.imageSourceAcross}
      style={{
        height: isDown ? cellSize * longSide : cellSize * shortSide,
        width: !isDown ? cellSize * longSide : cellSize * shortSide,
      }}
      onClick={onClick}
    />
  );
};

export const DraggableShip: FC<Props> = ({
  id,
  cellSize,
  orientation,
  onClick,
}) => {
  const ship = SHIP_INVENTORY[id];
  const [longSide, shortSide] = ship.dimensions;

  const isDown = orientation === "DOWN";

  const [{ isDragging }, drag] = useDrag(() => ({
    type: id,
    item: () => {
      if (onClick) {
        onClick();
      }
      return { id };
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <img
      ref={drag}
      id={id}
      alt={id}
      src={isDown ? ship.imageSourceDown : ship.imageSourceAcross}
      style={{
        height: isDown ? cellSize * longSide : cellSize * shortSide,
        width: !isDown ? cellSize * longSide : cellSize * shortSide,
        transform: `rotate(0)`, // this is required to make it clickable where it extends beyond its parent cell
        opacity: isDragging ? 0.5 : 1,
        cursor: "move",
      }}
      onClick={onClick}
    />
  );
};
