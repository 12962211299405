import { FC } from "react";
import classNames from "classnames";
import styles from "./Footer.module.scss";

type Props = {
  isIntroScreen?: boolean;
};

const Footer: FC<Props> = ({ isIntroScreen = false }) => {
  return (
    <div
      className={classNames([styles.Byline], {
        [styles.BylineIntro]: isIntroScreen,
      })}
    >
      {isIntroScreen && (
        <div className={styles.BestExperience}>Best experienced on desktop</div>
      )}
      <div>
        Made with ♥ by{" "}
        <a href="https://www.scoutlab.com/" target="_blank" rel="noreferrer">
          Scout Lab
        </a>{" "}
        and{" "}
        <a href="https://www.siliconrhino.io/" target="_blank" rel="noreferrer">
          Silicon Rhino
        </a>
      </div>
      <div>
        <a href="mailto:yachtleship@scoutlab.com">Yachtle at us</a>
      </div>
    </div>
  );
};

export default Footer;
