export const shipIDs = [
  "PUBLICIS",
  "OMNICOM",
  "TBWA",
  "HAVAS",
  "OGILVY",
  "WPP",
] as const;

export type ShipID = (typeof shipIDs)[number];

type Ship = {
  id: ShipID;
  company: string;
  captain: string;
  dimensions: [number, number];
  imageSourceAcross: string;
  imageSourceDown: string;
  imageSourceDock: string;
  imageSourceCaptain: string;
};

const PUBLICIS: Ship = {
  id: "PUBLICIS",
  company: "Publicis",
  captain: "Captain Sadoun",
  dimensions: [5, 2],
  imageSourceAcross: "/Yachts/Yacht6-horizontal.png",
  imageSourceDown: "/Yachts/Yacht6-vertical.png",
  imageSourceDock: "/Yachts/Yacht6-dock.png",
  imageSourceCaptain: "/Captains/Arthur_Sadoun_Publicis_Group_avatar.png",
};

const OMNICOM: Ship = {
  id: "OMNICOM",
  company: "Omnicom",
  captain: "Captain Wren",
  dimensions: [6, 1],
  imageSourceAcross: "/Yachts/Yacht1-horizontal.png",
  imageSourceDown: "/Yachts/Yacht1-vertical.png",
  imageSourceDock: "/Yachts/Yacht1-dock.png",
  imageSourceCaptain: "/Captains/John_Wren_Omnicom_avatar.png",
};

const TBWA: Ship = {
  id: "TBWA",
  company: "TBWA",
  captain: "Captain Ruhanen",
  dimensions: [5, 1],
  imageSourceAcross: "/Yachts/Yacht2-horizontal.png",
  imageSourceDown: "/Yachts/Yacht2-vertical.png",
  imageSourceDock: "/Yachts/Yacht2-dock.png",
  imageSourceCaptain: "/Captains/Troy_Ruhanen_TBWA_avatar.png",
};

const HAVAS: Ship = {
  id: "HAVAS",
  company: "Havas",
  captain: "Captain Bolloré",
  dimensions: [4, 1],
  imageSourceAcross: "/Yachts/Yacht3-horizontal.png",
  imageSourceDown: "/Yachts/Yacht3-vertical.png",
  imageSourceDock: "/Yachts/Yacht3-dock.png",
  imageSourceCaptain: "/Captains/Yannick_Bollore_Havas_avatar.png",
};

const OGILVY: Ship = {
  id: "OGILVY",
  company: "Ogilvy",
  captain: "Captain Seifert",
  dimensions: [4, 1],
  imageSourceAcross: "/Yachts/Yacht4-horizontal.png",
  imageSourceDown: "/Yachts/Yacht4-vertical.png",
  imageSourceDock: "/Yachts/Yacht4-dock.png",
  imageSourceCaptain: "/Captains/John_Seifert_Ogilvy_avatar.png",
};

const WPP: Ship = {
  id: "WPP",
  company: "WPP",
  captain: "Captain Read",
  dimensions: [3, 1],
  imageSourceAcross: "/Yachts/Yacht5-horizontal.png",
  imageSourceDown: "/Yachts/Yacht5-vertical.png",
  imageSourceDock: "/Yachts/Yacht5-dock.png",
  imageSourceCaptain: "/Captains/Mark_Read_WPP_avatar.png",
};

export const SHIP_INVENTORY: Record<ShipID, Ship> = {
  PUBLICIS,
  OMNICOM,
  TBWA,
  HAVAS,
  OGILVY,
  WPP,
};
