import { ReactNode } from "react";

export const FAQs: Array<{ question: string; answer: ReactNode }> = [
  {
    question: "What is Yachtleship?",
    answer: (
      <p>
        Yachtleship is a strategy game that allows you to participate in Cannes
        debauchery without the price tag. Organize your fleet of yachts and send
        your{" "}
        <a
          href="https://www.telegraph.co.uk/world-news/2023/05/23/killer-whale-gladis-gang-orcas-sinking-yachts-gibraltar/"
          target="_blank"
          rel="noreferrer"
        >
          killer whales
        </a>{" "}
        to sink your opponent's yachts. It's a single player game, so before AI
        takes your job, you can sink its ships.
      </p>
    ),
  },
  {
    question: "Why did y'all do this?",
    answer: (
      <p>
        Cannes is prohibitively expensive to enter and to participate in. The
        creative and advertising industries thrive when more voices from the
        global majority are included, but there is still a power structure that
        keeps underrepresented leaders from entering and succeeding in the
        industry. This game pokes at that truth, while having some fun. Also,
        save the whales.
      </p>
    ),
  },
  {
    question: "How do I play Yachtleship?",
    answer: (
      <p>
        First, place your yachts. Every yacht is attached to an agency that has
        rented a yacht for Cannes previously. Once your yachts are placed, now
        it's time to send your fleet of{" "}
        <a
          href="https://www.telegraph.co.uk/world-news/2023/05/23/killer-whale-gladis-gang-orcas-sinking-yachts-gibraltar/"
          target="_blank"
          rel="noreferrer"
        >
          killer whales
        </a>{" "}
        to attack your opponent’s yachts. Play against AI (before it destroys
        humanity - lol jk?) until either of you destroy each other's yachts. The
        player who destroys all of their opponent's yachts first wins.
      </p>
    ),
  },
  {
    question: "Who are the captains?",
    answer: (
      <p>
        Yachts in Yachtleship are identified by agency holding companies that
        have rented yachts at Cannes in the past. The captains are the CEOs of
        the holding companies - try and find similarities between them.
      </p>
    ),
  },
  {
    question: "Do I win anything?",
    answer: <p>No - this is meant to be a coping mechanism.</p>,
  },
];
