import { FC, ReactNode } from "react";
import classNames from "classnames";
import styles from "./Grid.module.scss";

export const Grid: FC<{ children: ReactNode }> = ({ children }) => (
  <div style={{ display: "flex", flexDirection: "column" }}>{children}</div>
);

export const GridRow: FC<{ children: ReactNode }> = ({ children }) => (
  <div style={{ display: "flex", flexDirection: "row" }}>{children}</div>
);

type GridCellProps = {
  cellSize: number;
  showClickStyle?: boolean;
  isClickableCell: boolean;
  isUnclickableCell: boolean;
  isSelectedCell: boolean;
  onClick?: () => void;
  children?: ReactNode;
};

export const GridCell: FC<GridCellProps> = ({
  cellSize,
  showClickStyle,
  isClickableCell,
  isUnclickableCell,
  isSelectedCell,
  onClick,
  children,
}) => (
  <div
    className={classNames(styles.GridCell, {
      [styles.ClickableCell]: showClickStyle && isClickableCell,
      [styles.UnclickableCell]: showClickStyle && isUnclickableCell,
      [styles.SelectedCell]: isSelectedCell,
    })}
    style={{ position: "relative", height: cellSize, width: cellSize }}
    onClick={onClick}
  >
    {children}
  </div>
);
