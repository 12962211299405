import { FC, useState } from "react";
import classNames from "classnames";
import { isMobile } from "../../gameplay/BattleshipsGame";
import Footer from "../Footer/Footer";
import FAQModal from "../Modal/FAQModal";
import styles from "./IntroScreen.module.scss";

type Props = {
  startGame: () => void;
};

const IntroScreen: FC<Props> = ({ startGame }) => {
  const [showFAQModal, setShowFAQModal] = useState(false);

  return (
    <div className={styles.ScreenContainer}>
      {isMobile ? (
        <img
          alt="orcas"
          src="/Homepage/SL_Yachtleship_Video_Mobile.gif"
          className={styles.IntroScreenVideo}
        />
      ) : (
        <video autoPlay loop muted className={styles.IntroScreenVideo}>
          <source src="/Homepage/SL_Yachtleship_Video.mp4" type="video/mp4" />
        </video>
      )}

      <div className={styles.IntroScreenContent}>
        <button
          className={styles.WTFButton}
          onClick={() => setShowFAQModal(true)}
        >
          WTF?
        </button>
        <div className={styles.IntroScreenLineHolder}>
          <h4
            className={classNames([
              styles.IntroScreenTagline,
              styles.GoldGradientText,
            ])}
          >
            The Cannes yacht party everyone can attend.
          </h4>
        </div>
        <div className={styles.IntroScreenLineHolder}>
          <h1
            className={classNames([
              styles.IntroScreenTitle,
              styles.GoldGradientText,
            ])}
          >
            Sink the Rich
          </h1>
        </div>
        <button className={styles.StartSinkingButton} onClick={startGame}>
          Start Sinking
        </button>
      </div>

      <Footer isIntroScreen />

      <FAQModal isOpen={showFAQModal} onClose={() => setShowFAQModal(false)} />
    </div>
  );
};

export default IntroScreen;
