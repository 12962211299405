import { FC, ReactNode } from "react";
import styles from "./IconButton.module.scss";

type Icon = {
  onClick: () => void;
  children: ReactNode;
  disabled?: boolean;
};

const IconButton: FC<Icon> = ({ onClick, disabled, children }) => (
  <button className={styles.IconButton} onClick={onClick} disabled={disabled}>
    {children}
  </button>
);

export default IconButton;
