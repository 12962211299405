import { ShipID, SHIP_INVENTORY } from "../content/Ships";
import { CellCoordinates } from "../types";

export const cloneArray = (arrayToClone: any[]) =>
  JSON.parse(JSON.stringify(arrayToClone));

export const cloneObject = (objectToClone: Object) =>
  JSON.parse(JSON.stringify(objectToClone));

export const cellIsWithinShip = (
  targetCell: CellCoordinates,
  shipTopLeftCell: CellCoordinates | undefined,
  shipID: ShipID,
  orientation: "ACROSS" | "DOWN"
): boolean => {
  if (!shipTopLeftCell) {
    return false;
  }

  const [longSide, shortSide] = SHIP_INVENTORY[shipID].dimensions;

  for (let i = 0; i < longSide; i++) {
    for (let j = 0; j < shortSide; j++) {
      if (
        orientation === "ACROSS" &&
        shipTopLeftCell.row + j === targetCell.row &&
        shipTopLeftCell.col + i === targetCell.col
      ) {
        return true;
      }
      if (
        orientation === "DOWN" &&
        shipTopLeftCell.row + i === targetCell.row &&
        shipTopLeftCell.col + j === targetCell.col
      ) {
        return true;
      }
    }
  }
  return false;
};

export const awaitTimeout = async (ms: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};
