import { useState, useRef } from "react";
import { BattleshipsGame } from "./gameplay/BattleshipsGame";
import { shipIDs } from "./content/Ships";
import IntroScreen from "./components/GameScreens/IntroScreen";
import PlaceShipsScreen from "./components/GameScreens/PlaceShipsScreen";
import GameScreen from "./components/GameScreens/GameScreen";
import styles from "./App.module.scss";

type Screen = "INTRO" | "PLACE_SHIPS" | "GAME";

const App = () => {
  const missileSound = useRef<HTMLAudioElement>(
    new Audio("/Audio/missile-firing.mp3")
  );
  const splashSound = useRef<HTMLAudioElement>(
    new Audio("/Audio/water-splash.mp3")
  );
  const tieFighterSound = useRef<HTMLAudioElement>(
    new Audio("/Audio/tie-fighter-explode.mp3")
  );
  const cashRegisterSound = useRef<HTMLAudioElement>(
    new Audio("/Audio/cash-register.mp3")
  );

  let playerGame = useRef<BattleshipsGame | undefined>();
  let aiGame = useRef<BattleshipsGame | undefined>();

  const [screen, setScreen] = useState<Screen>("INTRO");
  const [soundsOn, setSoundsOn] = useState(true);

  const startGame = () => {
    playerGame.current = new BattleshipsGame();
    aiGame.current = new BattleshipsGame();
    for (const shipID of shipIDs) {
      aiGame.current.placeShipRandomly(shipID);
    }
    setScreen("PLACE_SHIPS");
  };

  const exitGame = () => {
    playerGame.current = undefined;
    aiGame.current = undefined;
    setScreen("INTRO");
  };

  const setVolume = (volume: 0 | 1) => {
    missileSound.current.volume = volume;
    splashSound.current.volume = volume;
    tieFighterSound.current.volume = volume;
    cashRegisterSound.current.volume = volume;
    setSoundsOn(volume === 1);
  };

  return (
    <div className={styles.App}>
      {screen === "INTRO" && <IntroScreen startGame={startGame} />}
      {screen === "PLACE_SHIPS" && playerGame.current && (
        <PlaceShipsScreen
          game={playerGame.current}
          cashRegisterSound={cashRegisterSound.current}
          splashSound={splashSound.current}
          soundsOn={soundsOn}
          setVolume={setVolume}
          goToGame={() => setScreen("GAME")}
          exitGame={exitGame}
        />
      )}
      {screen === "GAME" && playerGame.current && aiGame.current && (
        <GameScreen
          playerGame={playerGame.current}
          aiGame={aiGame.current}
          missileSound={missileSound.current}
          splashSound={splashSound.current}
          tieFighterSound={tieFighterSound.current}
          cashRegisterSound={cashRegisterSound.current}
          soundsOn={soundsOn}
          setVolume={setVolume}
          exitGame={exitGame}
        />
      )}
    </div>
  );
};

export default App;
