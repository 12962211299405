import { FC, useState } from "react";
import { IoIosClose, IoIosVolumeHigh, IoIosVolumeOff } from "react-icons/io";
import { BattleshipsGame, CellStatus } from "../../gameplay/BattleshipsGame";
import { awaitTimeout } from "../../helpers";
import { CellCoordinates } from "../../types";
import GameGrid from "../GameGrid/GameGrid";
import ExitModal from "../Modal/ExitModal";
import FAQModal from "../Modal/FAQModal";
import ResultModal from "../Modal/ResultModal";
import Footer from "../Footer/Footer";
import styles from "./GameScreen.module.scss";

type Props = {
  playerGame: BattleshipsGame;
  aiGame: BattleshipsGame;
  missileSound: HTMLAudioElement;
  splashSound: HTMLAudioElement;
  tieFighterSound: HTMLAudioElement;
  cashRegisterSound: HTMLAudioElement;
  soundsOn: boolean;
  setVolume: (volume: 0 | 1) => void;
  exitGame: () => void;
};

const GameScreen: FC<Props> = ({
  playerGame,
  aiGame,
  missileSound,
  splashSound,
  tieFighterSound,
  cashRegisterSound,
  soundsOn,
  setVolume,
  exitGame,
}) => {
  const [nextMoveBoard, setNextMoveBoard] = useState<"PLAYER" | "AI">("AI");
  const [winner, setWinner] = useState<"PLAYER" | "AI" | undefined>();
  const [showExitModal, setShowExitModal] = useState(false);
  const [showResultModal, setShowResultModal] = useState(false);
  const [showFAQModal, setShowFAQModal] = useState(false);

  const move = async (
    board: "PLAYER" | "AI",
    cell: CellCoordinates
  ): Promise<CellStatus | null> => {
    const player = board === "AI" ? "PLAYER" : "AI";
    const game = board === "PLAYER" ? playerGame : aiGame;

    if (nextMoveBoard !== board) {
      return null;
    }

    missileSound.play();
    await awaitTimeout(1500);
    missileSound.pause();
    missileSound.currentTime = 0;

    const cellStatusAfterMove = game.shoot(cell);

    switch (cellStatusAfterMove) {
      case "NO_SHIP":
        splashSound.play();
        break;
      case "HIT_SHIP":
        tieFighterSound.play();
        break;
      case "HIT_COMPLETE_SHIP":
        cashRegisterSound.play();
        break;
    }

    if (game.isGameOver()) {
      setWinner(player);
      setShowResultModal(true);
    } else {
      setNextMoveBoard(player);
    }
    return cellStatusAfterMove;
  };

  return (
    <div className={styles.ScreenContainer}>
      <div className={styles.TopRightIconRow}>
        {soundsOn ? (
          <IoIosVolumeHigh
            className={styles.TopRightIcon}
            onClick={() => setVolume(0)}
          />
        ) : (
          <IoIosVolumeOff
            className={styles.TopRightIcon}
            onClick={() => setVolume(1)}
          />
        )}
        <IoIosClose
          className={styles.TopRightIcon}
          onClick={() => setShowExitModal(true)}
        />
      </div>
      <button
        className={styles.WTFButton}
        onClick={() => setShowFAQModal(true)}
      >
        WTF?
      </button>
      <h2 className={styles.Header}>Let's Yachtle</h2>
      <p className={styles.SubHeader}>
        Use your killer whales to knock your competitors out of the next RFP
      </p>
      <div className={styles.FullWidthSpacedRow}>
        <GameGrid
          title="Your Board"
          defendingPlayer="PLAYER"
          game={playerGame}
          hasNextMove={nextMoveBoard === "PLAYER"}
          move={(cell) => move("PLAYER", cell)}
        />
        <GameGrid
          title="Competitor's Board"
          defendingPlayer="AI"
          game={aiGame}
          hasNextMove={nextMoveBoard === "AI"}
          move={(cell) => move("AI", cell)}
        />
      </div>

      <Footer />

      <ExitModal
        isOpen={showExitModal}
        onClose={() => setShowExitModal(false)}
        exitGame={exitGame}
      />
      <ResultModal
        isOpen={showResultModal}
        onClose={() => setShowResultModal(false)}
        winner={winner}
        exitGame={exitGame}
      />
      <FAQModal isOpen={showFAQModal} onClose={() => setShowFAQModal(false)} />
    </div>
  );
};

export default GameScreen;
