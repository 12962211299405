import { ShipID, shipIDs, SHIP_INVENTORY } from "../content/Ships";
import { CellCoordinates } from "../types";

export const isMobile = window.innerWidth < 900;

export const BOARD_SIZE = isMobile ? 10 : 12;

export const CELL_WIDTH = 35;

type ShipState = {
  dimensions: [number, number];
  hits: number;
};

type ShipRecord = Record<ShipID, ShipState>;

export type CellStatus =
  | "UNKNOWN"
  | "NO_SHIP"
  | "HIT_SHIP"
  | "HIT_COMPLETE_SHIP";

type Cell = {
  ship: ShipID | null;
  status: CellStatus;
};

type Board = Cell[][];

export type ShipPositionBoard = (ShipID | null)[][];

export type StatusBoard = CellStatus[][];

export class BattleshipsGame {
  board: Board;
  ships: ShipRecord;

  constructor() {
    this.board = this.createEmptyBoard();
    this.ships = shipIDs.reduce((acc, id) => {
      const dimensions = SHIP_INVENTORY[id].dimensions;
      return {
        ...acc,
        [id]: { dimensions, hits: 0 },
      };
    }, {} as ShipRecord);
  }

  private boardCell = (row: number, col: number) => this.board[row][col];

  public getShipBoard = (): ShipPositionBoard =>
    this.board.map((row) => row.map((cell) => cell.ship));

  public getStatusBoard = (): StatusBoard =>
    this.board.map((row) => row.map((cell) => cell.status));

  public getSurvivingShipDimensions = (): Array<[number, number]> =>
    Object.values(this.ships)
      .filter((ship) => ship.hits < ship.dimensions[0] * ship.dimensions[1])
      .map((ship) => ship.dimensions);

  private createEmptyBoard = () => {
    const emptyBoard: Board = [];
    for (let row = 0; row < BOARD_SIZE; row++) {
      const rowOfEmptyCells: Cell[] = [];
      for (let col = 0; col < BOARD_SIZE; col++) {
        rowOfEmptyCells.push({ ship: null, status: "UNKNOWN" });
      }
      emptyBoard.push(rowOfEmptyCells);
    }
    return emptyBoard;
  };

  public unplaceAllShips = () => {
    this.board = this.createEmptyBoard();
  };

  public unplaceShip = (shipID: ShipID) => {
    for (let row = 0; row < BOARD_SIZE; row++) {
      for (let col = 0; col < BOARD_SIZE; col++) {
        if (this.boardCell(row, col).ship === shipID) {
          this.boardCell(row, col).ship = null;
        }
      }
    }
  };

  public isCellAdjacentToShip = (
    cell: CellCoordinates,
    shipToPlace?: ShipID
  ): boolean => {
    for (const i of [-1, 0, 1]) {
      for (const j of [-1, 0, 1]) {
        const row = cell.row + i;
        const col = cell.col + j;
        if (row >= 0 && row < BOARD_SIZE && col >= 0 && col < BOARD_SIZE) {
          const cellToTest = this.boardCell(row, col);
          if (
            cellToTest.ship !== null &&
            (!shipToPlace || cellToTest.ship !== shipToPlace)
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };

  public canPlaceShip = (
    cell: CellCoordinates,
    shipID: ShipID,
    orientation: "ACROSS" | "DOWN",
    cannotBeAdjacentToAnotherShip: boolean
  ): boolean => {
    const [longSide, shortSide] = this.ships[shipID].dimensions;

    if (orientation === "ACROSS") {
      if (
        cell.row < 0 ||
        cell.row + shortSide > BOARD_SIZE ||
        cell.col < 0 ||
        cell.col + longSide > BOARD_SIZE
      ) {
        return false;
      }
    }
    if (orientation === "DOWN") {
      if (
        cell.row < 0 ||
        cell.row + longSide > BOARD_SIZE ||
        cell.col < 0 ||
        cell.col + shortSide > BOARD_SIZE
      ) {
        return false;
      }
    }

    for (let i = 0; i < longSide; i++) {
      for (let j = 0; j < shortSide; j++) {
        const row = cell.row + (orientation === "DOWN" ? i : j);
        const col = cell.col + (orientation === "ACROSS" ? i : j);

        if (cannotBeAdjacentToAnotherShip) {
          // check the cell itself and its 8 surrounding cells
          if (this.isCellAdjacentToShip({ row, col }, shipID)) {
            return false;
          }
        } else {
          // check only the cell itself
          const c = this.boardCell(row, col);
          if (!(c.ship === shipID || c.ship === null)) {
            return false;
          }
        }
      }
    }

    return true;
  };

  public placeShip = (
    cell: CellCoordinates,
    shipID: ShipID,
    orientation: "ACROSS" | "DOWN"
  ): { shipPlaced: boolean } => {
    if (!this.canPlaceShip(cell, shipID, orientation, true)) {
      return { shipPlaced: false };
    }

    this.unplaceShip(shipID);

    const [longSide, shortSide] = this.ships[shipID].dimensions;

    for (let i = 0; i < longSide; i++) {
      for (let j = 0; j < shortSide; j++) {
        if (orientation === "ACROSS") {
          this.boardCell(cell.row + j, cell.col + i).ship = shipID;
        } else {
          this.boardCell(cell.row + i, cell.col + j).ship = shipID;
        }
      }
    }

    return { shipPlaced: true };
  };

  public placeShipRandomly = (
    shipID: ShipID
  ): { cell: CellCoordinates; orientation: "ACROSS" | "DOWN" } => {
    let placed = false;
    let row: number = 0;
    let col: number = 0;
    let orientation: "ACROSS" | "DOWN" = "ACROSS";
    while (!placed) {
      row = Math.floor(Math.random() * BOARD_SIZE);
      col = Math.floor(Math.random() * BOARD_SIZE);
      orientation = Math.random() < 0.5 ? "ACROSS" : "DOWN";
      if (this.canPlaceShip({ row, col }, shipID, orientation, true)) {
        this.placeShip({ row, col }, shipID, orientation);
        placed = true;
      }
    }
    return { cell: { row, col }, orientation };
  };

  public shoot = (cell: CellCoordinates): CellStatus | null => {
    const targetCell = this.boardCell(cell.row, cell.col);
    if (targetCell.status !== "UNKNOWN") {
      // cell already shot
      return null;
    }
    if (targetCell.ship === null) {
      targetCell.status = "NO_SHIP";
      return "NO_SHIP";
    }
    const ship = this.ships[targetCell.ship];
    ship.hits += 1;
    if (ship.hits === ship.dimensions[0] * ship.dimensions[1]) {
      this.sinkShip(targetCell.ship);
    } else {
      this.boardCell(cell.row, cell.col).status = "HIT_SHIP";
    }
    return this.boardCell(cell.row, cell.col).status;
  };

  private sinkShip = (shipID: ShipID) => {
    for (let row = 0; row < BOARD_SIZE; row++) {
      for (let col = 0; col < BOARD_SIZE; col++) {
        const gridCell = this.boardCell(row, col);
        if (gridCell.ship === shipID) {
          gridCell.status = "HIT_COMPLETE_SHIP";
        }
      }
    }
  };

  public isGameOver = (): boolean => {
    for (const ship of Object.values(this.ships)) {
      if (ship.hits < ship.dimensions[0] * ship.dimensions[1]) {
        return false;
      }
    }
    return true;
  };

  public printBoardShips = () => {
    let colIndexString = "   ";
    for (let j = 0; j < this.board[0].length; j++) {
      colIndexString += String(j).padEnd(4);
    }
    console.log(colIndexString);

    for (let i = 0; i < this.board.length; i++) {
      const row = this.board[i];
      console.log(
        `${String(i).padStart(2)} ${row
          .map((cell) => (cell.ship ? cell.ship.slice(0, 3) : ".  "))
          .join(" ")}`
      );
    }
  };

  public printBoard = () => {
    const cellStatusDisplay: Record<CellStatus, string> = {
      UNKNOWN: ".  ",
      NO_SHIP: "O  ",
      HIT_SHIP: "X  ",
      HIT_COMPLETE_SHIP: "XXX",
    };

    let colIndexString = "   ";
    for (let j = 0; j < this.board[0].length; j++) {
      colIndexString += String(j).padEnd(4);
    }
    console.log(colIndexString);

    for (let i = 0; i < this.board.length; i++) {
      const row = this.board[i];
      console.log(
        `${String(i).padStart(2)} ${row
          .map((cell) => cellStatusDisplay[cell.status])
          .join(" ")}`
      );
    }
  };
}
