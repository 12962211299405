import { FC } from "react";
import { IoIosClose } from "react-icons/io";
import { ShipID, SHIP_INVENTORY } from "../../content/Ships";
import styles from "./ShipCard.module.scss";

type Props = {
  shipID: ShipID | undefined;
  unselectShip: () => void;
};

const ShipCard: FC<Props> = ({ shipID, unselectShip }) => {
  if (!shipID) {
    return <div />;
  }

  const ship = SHIP_INVENTORY[shipID];

  return (
    <div className={styles.ShipCard}>
      <button className={styles.UnselectButton} onClick={unselectShip}>
        <IoIosClose className={styles.IconButtonIcon} />
      </button>
      <div className={styles.ShipCompanyText}>{ship.company}</div>
      <div className={styles.BannerContainer}>
        <img
          alt={`banner-${ship.captain}`}
          className={styles.Banner}
          src="/Captains/banner.png"
        />
        <img
          alt={`avatar-${ship.captain}`}
          className={styles.Avatar}
          src={ship.imageSourceCaptain}
        />
      </div>
      <h3 className={styles.ShipCaptain}>{ship.captain}</h3>
    </div>
  );
};

export default ShipCard;
