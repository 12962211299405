import { FC } from "react";
import { CELL_WIDTH } from "../../gameplay/BattleshipsGame";
import { ShipID, SHIP_INVENTORY } from "../../content/Ships";
import { DraggableShip } from "../Ship/Ship";
import styles from "./ShipDock.module.scss";

type Props = {
  shipID: ShipID;
  isDocked: boolean;
  selectShip: () => void;
  cellSize: number;
};

const ShipDock: FC<Props> = ({ shipID, isDocked, selectShip, cellSize }) => {
  const ship = SHIP_INVENTORY[shipID];

  return (
    <div
      style={{
        width: cellSize * ship.dimensions[0],
        height: cellSize * ship.dimensions[1],
      }}
    >
      {isDocked ? (
        <DraggableShip
          id={shipID}
          cellSize={CELL_WIDTH}
          orientation="ACROSS"
          onClick={selectShip}
        />
      ) : (
        <img
          alt={`dock-${ship.id}`}
          src={ship.imageSourceDock}
          className={styles.ShipDockImage}
        />
      )}
    </div>
  );
};

export default ShipDock;
