import { FC } from "react";
import Modal from "./_Modal";
import styles from "./ExitModal.module.scss";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  exitGame: () => void;
};

const ExitModal: FC<Props> = ({ isOpen, onClose, exitGame }) => {
  return (
    <Modal title="Exit Game" isOpen={isOpen} onClose={onClose} fullSize={false}>
      <p className={styles.ModalSubtext}>
        Are you sure you want to quit this game?
      </p>
      <div className={styles.flexEnd}>
        <button className={styles.ModalButton} onClick={exitGame}>
          Exit
        </button>
        <button className={styles.ModalButton} onClick={onClose}>
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default ExitModal;
