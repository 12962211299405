import { BOARD_SIZE, CellStatus, StatusBoard } from "./BattleshipsGame";
import { cloneArray } from "../helpers";
import { CellCoordinates } from "../types";

const isOnBoard = (cell: CellCoordinates): boolean =>
  cell.row >= 0 &&
  cell.row < BOARD_SIZE &&
  cell.col >= 0 &&
  cell.col < BOARD_SIZE;

type SurroundingCells = {
  above: CellCoordinates | null;
  below: CellCoordinates | null;
  left: CellCoordinates | null;
  right: CellCoordinates | null;
};

const getSurroundingCells = (cell: CellCoordinates): SurroundingCells => {
  const { row, col } = cell;
  const surroundingCells: SurroundingCells = {
    above: null,
    below: null,
    left: null,
    right: null,
  };
  const cellAbove = { row: row - 1, col };
  if (isOnBoard(cellAbove)) {
    surroundingCells.above = cellAbove;
  }
  const cellBelow = { row: row + 1, col };
  if (isOnBoard(cellBelow)) {
    surroundingCells.below = cellBelow;
  }
  const cellLeft = { row, col: col - 1 };
  if (isOnBoard(cellLeft)) {
    surroundingCells.left = cellLeft;
  }
  const cellRight = { row, col: col + 1 };
  if (isOnBoard(cellRight)) {
    surroundingCells.right = cellRight;
  }
  return surroundingCells;
};

export const hasShipIn8SurroundingCells = (
  cell: CellCoordinates,
  board: StatusBoard
): boolean => {
  for (const i of [-1, 0, 1]) {
    for (const j of [-1, 0, 1]) {
      const row = cell.row + i;
      const col = cell.col + j;
      if (isOnBoard({ row, col }) && board[row][col] === "HIT_COMPLETE_SHIP") {
        return true;
      }
    }
  }
  return false;
};

const getAlignmentClearLength = (
  cell: CellCoordinates,
  orientation: "ACROSS" | "DOWN",
  board: StatusBoard
): number => {
  if (!isOnBoard(cell)) return -1;

  const lineOfHit =
    orientation === "ACROSS"
      ? board[cell.row]
      : board.map((row) => row[cell.col]);

  const isHitOrUnknown = (lineIdx: number) =>
    lineOfHit[lineIdx] === "HIT_SHIP" || lineOfHit[lineIdx] === "UNKNOWN";

  let i = orientation === "ACROSS" ? cell.col : cell.row;

  // if cell itself doesn't match criteria, there is no clear alignment
  if (!isHitOrUnknown(i)) return 0;

  // step backwards to (one before) start of clear run
  while (i >= 0 && isHitOrUnknown(i)) {
    i -= 1;
  }
  // one forwards (last iteration above), one margin from complete ship
  if (lineOfHit[i] === "HIT_COMPLETE_SHIP") {
    i += 2;
  } else {
    i += 1;
  }
  // count forwards to end of clear run
  let clearLength = 0;
  while (i < BOARD_SIZE && isHitOrUnknown(i)) {
    clearLength += 1;
    i += 1;
  }
  // one margin from complete ship
  if (lineOfHit[i] === "HIT_COMPLETE_SHIP") {
    clearLength -= 1;
  }

  return clearLength;
};

const getBoardByCol = (board: StatusBoard): CellStatus[][] => {
  const boardByCol: CellStatus[][] = [];
  for (let i = 0; i < BOARD_SIZE; i++) {
    const newRow: CellStatus[] = [];
    for (let j = 0; j < BOARD_SIZE; j++) {
      newRow.push(board[j][i]);
    }
    boardByCol.push(newRow);
  }
  return boardByCol;
};

const countRowsWithHits = (board: StatusBoard): number =>
  board.filter((row) => row.includes("HIT_SHIP")).length;

const countColsWithHits = (board: StatusBoard): number =>
  getBoardByCol(board).filter((row) => row.includes("HIT_SHIP")).length;

const hitWouldCreate3x3HitArea = (
  cell: CellCoordinates,
  board: StatusBoard
) => {
  const newBoard = cloneArray(board);
  newBoard[cell.row][cell.col] = "HIT_SHIP";
  return countRowsWithHits(newBoard) > 2 && countColsWithHits(newBoard) > 2;
};

const arrayIncludesCell = (array: CellCoordinates[], cell: CellCoordinates) => {
  for (const i of array) {
    if (i.row === cell.row && i.col === cell.col) {
      return true;
    }
  }
  return false;
};

export const getAIPlayerMove = (
  board: StatusBoard,
  survivingShipDimensions: Array<[number, number]>
): CellCoordinates => {
  const isHit = (cell: CellCoordinates | null) =>
    !!cell && isOnBoard(cell) && board[cell.row][cell.col] === "HIT_SHIP";

  const isUnknown = (cell: CellCoordinates | null) =>
    !!cell && isOnBoard(cell) && board[cell.row][cell.col] === "UNKNOWN";

  const minSurvivingShipLength = Math.min(
    ...survivingShipDimensions.map((item) => item[0])
  );

  const maxSurvivingShipLength = Math.max(
    ...survivingShipDimensions.map((item) => item[0])
  );

  const hitCells: CellCoordinates[] = [];
  const unknownCellsNextToHits: CellCoordinates[] = [];
  const unknownCellsNotBorderingShips: CellCoordinates[] = [];

  for (let row = 0; row < BOARD_SIZE; row++) {
    for (let col = 0; col < BOARD_SIZE; col++) {
      if (isHit({ row, col })) {
        if (!arrayIncludesCell(hitCells, { row, col })) {
          hitCells.push({ row, col });
        }
        const { above, below, left, right } = getSurroundingCells({ row, col });
        for (const c of [above, below, left, right]) {
          if (!!c && isUnknown(c)) {
            const {
              above: aboveC,
              below: belowC,
              left: leftC,
              right: rightC,
            } = getSurroundingCells(c);
            // if cell is in the corner of two diagonally adjacent hit cells,
            // it must be in the second line of the 2-D ship: return
            if (
              (isHit(aboveC) || isHit(belowC)) &&
              (isHit(leftC) || isHit(rightC))
            ) {
              return c;
            }
            // otherwise push to array of unhit cells adjacent to hit cells
            if (!arrayIncludesCell(unknownCellsNextToHits, c)) {
              unknownCellsNextToHits.push(c);
            }
          }
        }
      }
      if (
        isUnknown({ row, col }) &&
        !hasShipIn8SurroundingCells({ row, col }, board) &&
        !arrayIncludesCell(unknownCellsNotBorderingShips, { row, col })
      ) {
        unknownCellsNotBorderingShips.push({ row, col });
      }
    }
  }

  if (hitCells.length === 1) {
    // if there is only one hit, consider the 4 surrounding cells, find the one that gives the longest clear alignment
    const hitCell = hitCells[0];
    const clearAcross = getAlignmentClearLength(hitCell, "ACROSS", board);
    const clearDown = getAlignmentClearLength(hitCell, "DOWN", board);
    const { above, below, left, right } = getSurroundingCells(hitCell);
    if (clearDown > clearAcross) {
      if (!!above && isUnknown(above)) return above;
      if (!!below && isUnknown(below)) return below;
    } else {
      if (!!left && isUnknown(left)) return left;
      if (!!right && isUnknown(right)) return right;
    }
  }

  for (const { row, col } of unknownCellsNextToHits) {
    // try to find a cell that continues a 2-cell hit alignment that could be extended to length of smallest surviving ship
    const clearAcross = getAlignmentClearLength({ row, col }, "ACROSS", board);
    const clearDown = getAlignmentClearLength({ row, col }, "DOWN", board);

    if (!hitWouldCreate3x3HitArea({ row, col }, board)) {
      if (
        clearAcross >= minSurvivingShipLength &&
        ((isHit({ row, col: col - 1 }) && isHit({ row, col: col - 2 })) ||
          (isHit({ row, col: col + 1 }) && isHit({ row, col: col + 2 })))
      ) {
        return { row, col };
      }

      if (
        clearDown >= minSurvivingShipLength &&
        ((isHit({ row: row - 1, col }) && isHit({ row: row - 2, col })) ||
          (isHit({ row: row + 1, col }) && isHit({ row: row + 2, col })))
      ) {
        return { row, col };
      }
    }
  }

  for (const cell of unknownCellsNextToHits) {
    // try to find a cell next to a hit that will place at least the shortest surviving ship
    if (!hitWouldCreate3x3HitArea(cell, board)) {
      const { above, below, left, right } = getSurroundingCells(cell);
      if (isHit(above) || isHit(below)) {
        const clearAlign = getAlignmentClearLength(cell, "DOWN", board);
        if (clearAlign >= minSurvivingShipLength) {
          return cell;
        }
      }
      if (isHit(left) || isHit(right)) {
        const clearAlign = getAlignmentClearLength(cell, "ACROSS", board);
        if (clearAlign >= minSurvivingShipLength) {
          return cell;
        }
      }
    }
  }

  // if there are cells next to hits and one still hasn't been returned (it should have been), return the first one
  if (unknownCellsNextToHits.length > 0) {
    return unknownCellsNextToHits[0];
  }

  // pick randomly from possible cells and check that it could place the longest surviving ship
  let cell: CellCoordinates = { row: 0, col: 0 };
  let possibleCell = false;
  while (!possibleCell) {
    const idx = Math.floor(
      Math.random() * unknownCellsNotBorderingShips.length
    );
    cell = unknownCellsNotBorderingShips[idx];
    const clearAlignDown = getAlignmentClearLength(cell, "DOWN", board);
    const clearAlignAcross = getAlignmentClearLength(cell, "ACROSS", board);
    if (
      clearAlignDown >= maxSurvivingShipLength ||
      clearAlignAcross >= maxSurvivingShipLength
    ) {
      possibleCell = true;
    }
  }
  return cell;
};
