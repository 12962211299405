import { FC, useState, useCallback, useEffect } from "react";
import {
  BattleshipsGame,
  BOARD_SIZE,
  CellStatus,
  CELL_WIDTH,
  StatusBoard,
} from "../../gameplay/BattleshipsGame";
import {
  getAIPlayerMove,
  hasShipIn8SurroundingCells,
} from "../../gameplay/AIPlayer";
import { Grid, GridRow, GridCell } from "../Grid/Grid";
import { Ship } from "../Ship/Ship";
import { Explosion, NoShip } from "./GameGridIcons";
import { CellCoordinates, ShipsByFirstCell } from "../../types";
import styles from "./GameGrid.module.scss";
import { awaitTimeout } from "../../helpers";

type Props = {
  title: string;
  defendingPlayer: "PLAYER" | "AI";
  game: BattleshipsGame;
  hasNextMove: boolean;
  move: (cell: CellCoordinates) => Promise<CellStatus | null>;
};

const GameGrid: FC<Props> = ({
  title,
  defendingPlayer,
  game,
  hasNextMove,
  move,
}) => {
  const [statusBoard, setStatusBoard] = useState<StatusBoard>([]);
  const [shipsToShow, setShipsToShow] = useState<ShipsByFirstCell>({});
  const [displayAnimation, setDisplayAnimation] = useState(false);
  const [currentMoveCell, setCurrentMoveCell] = useState<
    CellCoordinates | undefined
  >();

  const setStatusCell = (cell: CellCoordinates, status: CellStatus) =>
    setStatusBoard((previousBoard) => {
      const newBoard = [...previousBoard];
      newBoard[cell.row][cell.col] = status;
      return newBoard;
    });

  const makeMove = useCallback(
    async (cell: CellCoordinates) => {
      if (currentMoveCell) return;
      setCurrentMoveCell(cell);
      const moveToMake = await move(cell);
      if (moveToMake) {
        setStatusCell(cell, moveToMake);
      }
      setDisplayAnimation(true);
      await awaitTimeout(2000);
      setDisplayAnimation(false);
      setCurrentMoveCell(undefined);
    },
    [move, currentMoveCell]
  );

  const makePrivilegeMove = useCallback(() => {
    const shipBoard = game.getShipBoard();
    const cellsNotShot: CellCoordinates[] = [];
    for (let i = 0; i < BOARD_SIZE; i++) {
      for (let j = 0; j < BOARD_SIZE; j++) {
        if (!!shipBoard[i][j] && statusBoard[i][j] === "UNKNOWN") {
          cellsNotShot.push({ row: i, col: j });
        }
      }
    }
    const cellIndexToShoot = Math.floor(Math.random() * cellsNotShot.length);
    makeMove(cellsNotShot[cellIndexToShoot]);
  }, [game, statusBoard, makeMove]);

  useEffect(() => {
    // set the initial board state
    setStatusBoard(game.getStatusBoard());
  }, [game]);

  useEffect(() => {
    // if this is the player's board, set the ship positions to show
    if (defendingPlayer === "PLAYER") {
      const shipBoard = game.getShipBoard();
      const newShipsToShow: ShipsByFirstCell = {};
      for (let row = 0; row < BOARD_SIZE; row++) {
        for (let col = 0; col < BOARD_SIZE; col++) {
          const cellShipID = shipBoard[row][col];
          if (
            cellShipID &&
            !Object.values(newShipsToShow).find(
              (item) => item.shipID === cellShipID
            )
          ) {
            newShipsToShow[`${row},${col}`] = {
              shipID: cellShipID,
              orientation:
                shipBoard[row][col + 2] === cellShipID ? "ACROSS" : "DOWN",
            };
          }
        }
      }
      setShipsToShow(newShipsToShow);
    }
  }, [game, defendingPlayer]);

  useEffect(() => {
    // whenever it is the player board's move, make AI move
    if (defendingPlayer === "PLAYER" && hasNextMove) {
      const aiMoveCell = getAIPlayerMove(
        game.getStatusBoard(),
        game.getSurvivingShipDimensions()
      );
      setTimeout(() => {
        makeMove(aiMoveCell);
      }, 2000);
    }
  }, [game, defendingPlayer, hasNextMove, makeMove]);

  return (
    <div className={styles.GameGrid}>
      <div className={styles.TitleContainer}>
        <p className={styles.Title}>{title}</p>
        {defendingPlayer === "AI" && (
          <button
            className={styles.PrivilegeButton}
            disabled={!hasNextMove || !!currentMoveCell}
            onClick={makePrivilegeMove}
          >
            Use my privilege
          </button>
        )}
      </div>

      <Grid>
        {statusBoard.map((row, rowIdx) => (
          <GridRow key={rowIdx}>
            {row.map((cellStatus, colIdx) => {
              const cell = { row: rowIdx, col: colIdx };
              const isCurrentMoveCell =
                !!currentMoveCell &&
                currentMoveCell.row === rowIdx &&
                currentMoveCell.col === colIdx;
              const shipData = shipsToShow[`${rowIdx},${colIdx}`];
              const unclickable =
                cellStatus !== "UNKNOWN" ||
                hasShipIn8SurroundingCells(cell, game.getStatusBoard());
              const surrounding =
                (cellStatus === "UNKNOWN" || cellStatus === "NO_SHIP") &&
                hasShipIn8SurroundingCells(cell, game.getStatusBoard());
              const displayMissileOrca = isCurrentMoveCell && !displayAnimation;

              return (
                <GridCell
                  key={colIdx}
                  cellSize={CELL_WIDTH}
                  showClickStyle={defendingPlayer === "AI"}
                  isClickableCell={
                    hasNextMove && !unclickable && !currentMoveCell
                  }
                  isUnclickableCell={unclickable}
                  isSelectedCell={displayMissileOrca}
                  onClick={
                    defendingPlayer === "AI" && !unclickable
                      ? () => makeMove(cell)
                      : undefined
                  }
                >
                  {!!shipData && (
                    <Ship
                      id={shipData.shipID}
                      cellSize={CELL_WIDTH}
                      orientation={shipData.orientation}
                    />
                  )}
                  {(cellStatus === "HIT_SHIP" ||
                    cellStatus === "HIT_COMPLETE_SHIP") && (
                    <>
                      <Explosion
                        width={CELL_WIDTH}
                        height={CELL_WIDTH}
                        color="red"
                      />
                      {displayAnimation && isCurrentMoveCell && (
                        <img
                          alt="discard"
                          src={`/Animations/explosion.gif`}
                          width={4 * CELL_WIDTH}
                          style={{
                            position: "absolute",
                            left: `${-1.5 * CELL_WIDTH}px`,
                            top: `${-1.5 * CELL_WIDTH}px`,
                            zIndex: 99999,
                          }}
                        />
                      )}
                    </>
                  )}
                  {cellStatus === "NO_SHIP" && !surrounding && (
                    <>
                      <NoShip
                        width={CELL_WIDTH}
                        height={CELL_WIDTH}
                        color="#7fad8c"
                      />
                      {displayAnimation && isCurrentMoveCell && (
                        <img
                          alt="orca"
                          src={`/Animations/splash.gif`}
                          width={6 * CELL_WIDTH}
                          style={{
                            position: "absolute",
                            left: `${-2.5 * CELL_WIDTH}px`,
                            top: `${-2 * CELL_WIDTH}px`,
                            zIndex: 99999,
                          }}
                        />
                      )}
                    </>
                  )}
                  {surrounding && (
                    <img
                      alt="orca"
                      src={`/Orcas/orca${(colIdx + rowIdx) % 6}.png`}
                      width={CELL_WIDTH}
                    />
                  )}
                  {displayMissileOrca && (
                    <img
                      alt="orca"
                      src={`/Orcas/orca${defendingPlayer === "AI" ? 1 : 6}.png`}
                      width={CELL_WIDTH}
                      className={
                        defendingPlayer === "AI"
                          ? styles.MissileOrcaAI
                          : styles.MissileOrcaPlayer
                      }
                    />
                  )}
                </GridCell>
              );
            })}
          </GridRow>
        ))}
      </Grid>
    </div>
  );
};

export default GameGrid;
