import { FC, useState, useRef } from "react";
import classNames from "classnames";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import toast, { Toaster, ToastBar } from "react-hot-toast";
import { IoIosClose, IoIosVolumeHigh, IoIosVolumeOff } from "react-icons/io";
import { HiArrowUturnRight } from "react-icons/hi2";
import {
  BattleshipsGame,
  BOARD_SIZE,
  CELL_WIDTH,
} from "../../gameplay/BattleshipsGame";
import { ShipID, shipIDs } from "../../content/Ships";
import { cellIsWithinShip, cloneObject } from "../../helpers";
import BackgroundShipDrop from "../BackgroundShipDrop";
import DraggableGrid from "../Grid/DraggableGrid";
import IconButton from "../IconButton/IconButton";
import { DraggableShip } from "../Ship/Ship";
import ShipCard from "../ShipCard/ShipCard";
import ShipDock from "../ShipDock/ShipDock";
import Footer from "../Footer/Footer";
import ExitModal from "../Modal/ExitModal";
import { CellCoordinates, ShipPositionRecord, StylesByCell } from "../../types";
import styles from "./PlaceShipsScreen.module.scss";

const emptyShipPositionRecord: ShipPositionRecord = {
  PUBLICIS: { cell: undefined, orientation: "ACROSS" },
  OMNICOM: { cell: undefined, orientation: "ACROSS" },
  TBWA: { cell: undefined, orientation: "ACROSS" },
  HAVAS: { cell: undefined, orientation: "ACROSS" },
  OGILVY: { cell: undefined, orientation: "ACROSS" },
  WPP: { cell: undefined, orientation: "ACROSS" },
};

type Props = {
  game: BattleshipsGame;
  cashRegisterSound: HTMLAudioElement;
  splashSound: HTMLAudioElement;
  soundsOn: boolean;
  setVolume: (volume: 0 | 1) => void;
  goToGame: () => void;
  exitGame: () => void;
};

const PlaceShipsScreen: FC<Props> = ({
  game,
  cashRegisterSound,
  splashSound,
  soundsOn,
  setVolume,
  goToGame,
  exitGame,
}) => {
  const [shipPositions, setShipPositions] = useState<ShipPositionRecord>(
    cloneObject(emptyShipPositionRecord)
  );
  const shipPositionsRef = useRef<ShipPositionRecord>(shipPositions);

  const [selectedShip, setSelectedShip] = useState<ShipID | undefined>();
  const [stylesByCell, setStylesByCell] = useState<StylesByCell>({});
  const [showExitModal, setShowExitModal] = useState(false);

  const shipRotationDisabled =
    !selectedShip || !shipPositions[selectedShip].cell;

  const noShipPlaced = !shipIDs.find((id) => !!shipPositions[id].cell);

  const selectShipAndUpdateStyles = (shipID: ShipID) => {
    setSelectedShip(shipID);
    const shipPos = shipPositionsRef.current[shipID];
    updateStylesByCell(shipID, shipPos.cell, shipPos.orientation);
  };

  const selectDockedShipAndUpdateStyles = (shipID: ShipID) => {
    setSelectedShip(shipID);
    updateStylesByCell(shipID, undefined, undefined);
  };

  const unselectShipAndUpdateStyles = () => {
    setSelectedShip(undefined);
    updateStylesByCell(undefined, undefined, undefined);
  };

  const setShipPosition = (
    shipID: ShipID,
    cell: CellCoordinates | undefined,
    orientation: "ACROSS" | "DOWN"
  ) =>
    setShipPositions((positions) => {
      const newPositions = { ...positions, [shipID]: { cell, orientation } };
      shipPositionsRef.current = newPositions;
      return newPositions;
    });

  const placeShipsRandomly = () => {
    const focusShip = selectedShip || "PUBLICIS";
    let focusCell: CellCoordinates | undefined = undefined;
    let focusOrientation: "ACROSS" | "DOWN" | undefined = undefined;

    for (const shipID of shipIDs) {
      const { cell, orientation } = game.placeShipRandomly(shipID);
      if (shipID === focusShip) {
        focusCell = cell;
        focusOrientation = orientation;
      }
      setShipPosition(shipID, cell, orientation);
    }
    splashSound.play();
    if (!selectedShip) {
      setSelectedShip("PUBLICIS");
    }
    updateStylesByCell(focusShip, focusCell, focusOrientation);
  };

  const unplaceAllShips = () => {
    game.unplaceAllShips();
    setShipPositions(cloneObject(emptyShipPositionRecord));
    shipPositionsRef.current = cloneObject(emptyShipPositionRecord);
    unselectShipAndUpdateStyles();
    if (!noShipPlaced) {
      cashRegisterSound.play();
    }
  };

  const onDropShip = (shipID: ShipID, cell: CellCoordinates) => {
    const orientation = shipPositionsRef.current[shipID].orientation;
    const { shipPlaced } = game.placeShip(cell, shipID, orientation);
    if (shipPlaced) {
      setShipPosition(shipID, cell, orientation);
      splashSound.play();
    }
    updateStylesByCell(shipID, cell, orientation);
  };

  const onDockShip = (shipID: ShipID) => {
    game.unplaceShip(shipID);
    if (!!shipPositionsRef.current[shipID].cell) {
      cashRegisterSound.play();
    }
    setShipPosition(shipID, undefined, "ACROSS");
    updateStylesByCell(shipID, undefined, undefined);
  };

  const rotateShip = (shipID: ShipID) => {
    const shipPos = shipPositionsRef.current[shipID];
    if (!shipPos.cell) return;
    const orientation = shipPos.orientation === "ACROSS" ? "DOWN" : "ACROSS";
    if (!game.canPlaceShip(shipPos.cell, shipID, orientation, true)) {
      toast("You don't have space to rotate this ship!");
    } else {
      const { shipPlaced } = game.placeShip(shipPos.cell, shipID, orientation);
      if (shipPlaced) {
        setShipPosition(shipID, shipPos.cell, orientation);
        splashSound.play();
      }
      updateStylesByCell(shipID, shipPos.cell, orientation);
    }
  };

  const checkAllShipsPlaced = (): boolean => {
    for (const id of shipIDs) {
      if (shipPositions[id].cell === undefined) {
        return false;
      }
    }
    return true;
  };

  const updateStylesByCell = (
    focusShip: ShipID | undefined,
    focusShipTopLeft: CellCoordinates | undefined,
    focusShipOrientation: "ACROSS" | "DOWN" | undefined
  ) => {
    const newStylesByCell: StylesByCell = {};
    for (let row = 0; row < BOARD_SIZE; row++) {
      for (let col = 0; col < BOARD_SIZE; col++) {
        if (!!focusShip && !!focusShipTopLeft && !!focusShipOrientation) {
          if (
            game.canPlaceShip(
              focusShipTopLeft,
              focusShip,
              focusShipOrientation,
              true
            ) &&
            cellIsWithinShip(
              { row, col },
              focusShipTopLeft,
              focusShip,
              focusShipOrientation
            )
          ) {
            newStylesByCell[`${row},${col}`] = { backgroundColor: "#7FAD8C" };
          }
        }
        if (game.isCellAdjacentToShip({ row, col }, focusShip)) {
          newStylesByCell[`${row},${col}`] = { backgroundColor: "#005B19" };
        }
      }
    }
    setStylesByCell(newStylesByCell);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <BackgroundShipDrop
        className={styles.ScreenContainer}
        onDrop={onDockShip}
        useDropDependencies={[]}
        onHover={(shipID) => updateStylesByCell(shipID, undefined, undefined)}
      >
        <div className={styles.TopRightIconRow}>
          {soundsOn ? (
            <IoIosVolumeHigh
              className={styles.TopRightIcon}
              onClick={() => setVolume(0)}
            />
          ) : (
            <IoIosVolumeOff
              className={styles.TopRightIcon}
              onClick={() => setVolume(1)}
            />
          )}
          <IoIosClose
            className={styles.TopRightIcon}
            onClick={() => setShowExitModal(true)}
          />
        </div>

        <div className={styles.TopLeftContainer}>
          <div className={classNames([styles.TopLeftText, styles.bold])}>
            BATTLE OF THE AGENCIES
          </div>
          <div className={styles.TopLeftText}>SINK THE RICH</div>
        </div>

        <h2>PLACE YOUR YACHTS</h2>

        <div className={styles.FullWidthSpacedRow}>
          <div className={styles.DockOuterContainer}>
            <div className={styles.DockInnerContainer}>
              <ShipDock
                shipID="PUBLICIS"
                isDocked={shipPositions.PUBLICIS.cell === undefined}
                selectShip={() => selectDockedShipAndUpdateStyles("PUBLICIS")}
                cellSize={CELL_WIDTH}
              />
              <ShipDock
                shipID="OMNICOM"
                isDocked={shipPositions.OMNICOM.cell === undefined}
                selectShip={() => selectDockedShipAndUpdateStyles("OMNICOM")}
                cellSize={CELL_WIDTH}
              />
              <ShipDock
                shipID="TBWA"
                isDocked={shipPositions.TBWA.cell === undefined}
                selectShip={() => selectDockedShipAndUpdateStyles("TBWA")}
                cellSize={CELL_WIDTH}
              />
              <ShipDock
                shipID="HAVAS"
                isDocked={shipPositions.HAVAS.cell === undefined}
                selectShip={() => selectDockedShipAndUpdateStyles("HAVAS")}
                cellSize={CELL_WIDTH}
              />
              <ShipDock
                shipID="OGILVY"
                isDocked={shipPositions.OGILVY.cell === undefined}
                selectShip={() => selectDockedShipAndUpdateStyles("OGILVY")}
                cellSize={CELL_WIDTH}
              />
              <ShipDock
                shipID="WPP"
                isDocked={shipPositions.WPP.cell === undefined}
                selectShip={() => selectDockedShipAndUpdateStyles("WPP")}
                cellSize={CELL_WIDTH}
              />
            </div>
          </div>
          <DraggableGrid
            rows={BOARD_SIZE}
            columns={BOARD_SIZE}
            cellSize={CELL_WIDTH}
            dragTypesAccepted={[...shipIDs]}
            useDropDependencies={[]}
            onDrop={(id, cell) => onDropShip(id as ShipID, cell)}
            canDrop={(id, cell) => {
              const shipID = id as ShipID;
              const orientation = shipPositionsRef.current[shipID].orientation;
              return game.canPlaceShip(cell, shipID, orientation, true);
            }}
            onHover={(id, cell) => {
              const shipID = id as ShipID;
              const orientation = shipPositionsRef.current[shipID].orientation;
              updateStylesByCell(id as ShipID, cell, orientation);
            }}
            stylesByCell={stylesByCell}
            childrenByCell={shipIDs.reduce((acc, id) => {
              const shipPos = shipPositions[id];
              if (!shipPos.cell) return acc;
              const { row, col } = shipPos.cell;
              return {
                ...acc,
                [`${row},${col}`]: (
                  <DraggableShip
                    id={id}
                    cellSize={CELL_WIDTH}
                    orientation={shipPos.orientation}
                    onClick={() => selectShipAndUpdateStyles(id)}
                  />
                ),
              };
            }, {})}
          />
          <div className={styles.SidePanel}>
            <ShipCard
              shipID={selectedShip}
              unselectShip={() => unselectShipAndUpdateStyles()}
            />
            <div className={styles.IconButtonRow}>
              <IconButton onClick={() => placeShipsRandomly()}>
                <img
                  alt="shuffle"
                  src="/Icons/icon-shuffle.png"
                  className={styles.ShuffleIcon}
                />
              </IconButton>
              <IconButton
                disabled={shipRotationDisabled}
                onClick={() => {
                  if (!selectedShip) return;
                  rotateShip(selectedShip);
                }}
              >
                <HiArrowUturnRight className={styles.RotateIcon} />
              </IconButton>
              <IconButton onClick={() => unplaceAllShips()}>
                <img
                  alt="discard"
                  src="/Icons/icon-trash.png"
                  className={styles.TrashIcon}
                />
              </IconButton>
            </div>
          </div>
        </div>

        <div className={styles.padding16}>
          <button
            className={styles.GoldGradientButton}
            disabled={!checkAllShipsPlaced()}
            onClick={() => goToGame()}
          >
            START GAME
          </button>
        </div>

        <Footer />

        <ExitModal
          isOpen={showExitModal}
          onClose={() => setShowExitModal(false)}
          exitGame={exitGame}
        />
      </BackgroundShipDrop>

      <Toaster>
        {(t) => (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <>
                {icon}
                {message}
                {t.type !== "loading" && (
                  <button
                    className={styles.ToastButton}
                    onClick={() => toast.dismiss(t.id)}
                  >
                    <IoIosClose className={styles.IconButtonIcon} />
                  </button>
                )}
              </>
            )}
          </ToastBar>
        )}
      </Toaster>
    </DndProvider>
  );
};

export default PlaceShipsScreen;
