import { FC } from "react";
import { FAQs } from "../../content/FAQs";
import Modal from "./_Modal";
import styles from "./FAQModal.module.scss";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const FAQModal: FC<Props> = ({ isOpen, onClose }) => {
  return (
    <Modal
      title="FAQs"
      titleStyle={{ textTransform: "none" }}
      isOpen={isOpen}
      onClose={onClose}
    >
      {FAQs.map(({ question, answer }, index) => (
        <div key={index} className={styles.ModalContent}>
          <h5>{question}</h5>
          {answer}
        </div>
      ))}
    </Modal>
  );
};

export default FAQModal;
