import { FC } from "react";
import Modal from "./_Modal";
import styles from "./ResultModal.module.scss";

type Props = {
  isOpen: boolean;
  winner: "PLAYER" | "AI" | undefined;
  onClose: () => void;
  exitGame: () => void;
};

const ResultModal: FC<Props> = ({ isOpen, winner, onClose, exitGame }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={styles.ModalContents}>
        <h1 className={styles.WinLoseText}>
          {winner === "PLAYER" && "You win!"}
          {winner === "AI" && "You lose!"}
        </h1>
        <p className={styles.WinLoseSubtext}>
          {winner === "PLAYER" &&
            "Now, continue defeating the status quo by promoting diverse leaders in your organization."}
          {winner === "AI" &&
            "The status quo defeated you, but you can still be on the winning side by promoting diverse leaders in your organization."}
        </p>
        <div>
          <button className={styles.ModalButton} onClick={exitGame}>
            Play Again
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ResultModal;
