import { FC, ReactNode, CSSProperties } from "react";
import classNames from "classnames";
import { IoIosClose } from "react-icons/io";
import styles from "./_Modal.module.scss";

type Props = {
  isOpen: boolean;
  onClose?: () => void;
  title?: string;
  titleStyle?: CSSProperties;
  fullSize?: boolean;
  children: ReactNode;
};

const Modal: FC<Props> = ({
  isOpen,
  onClose,
  title,
  titleStyle,
  fullSize = true,
  children,
}) => {
  return (
    <div className={isOpen ? styles.DarkBackground : styles.HideBackground}>
      <dialog
        className={classNames(styles.Dialog, {
          [styles.FullSizeDialog]: fullSize,
        })}
        open={isOpen}
      >
        {!!onClose && (
          <IoIosClose className={styles.CloseIcon} onClick={onClose} />
        )}
        <div className={styles.DialogContainer}>
          {!!title && (
            <h2 className={styles.DialogTitle} style={titleStyle}>
              {title}
            </h2>
          )}
          <div className={styles.DialogInnerContainer}>{children}</div>
        </div>
      </dialog>
    </div>
  );
};

export default Modal;
